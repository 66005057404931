import {mapGetters} from 'vuex';
import Pagination from './../../Common/Pagination/index.vue';
import OnePublicationStat from './OnePublicationStat/index.vue';
import _laxios from '../../../store/_laxios';
import moment from 'moment';

const {get, set} = require('../../../helpers/cookie');
const EXPIRES = 2592000;

export default {
	name: 'PublicationsStatistic',
	data: function () {
		return {
			page: 1,
			pages: 0,
			limit: _TARGET_ === 'client' ? (Number(get('cabinet_ipp_publications')) || 10) : 10,
			publications: {},
			minDateFrom: undefined,
			monthData: undefined,
			currentPage: [],
			count: undefined,
			countAdd: 0,
			countRemove: 0,
			action: 0,
			requestCount: 0
		};
	},
	components: {
		'pagination': Pagination,
		'one-publication-stat': OnePublicationStat
	},
	mounted() {
		// удалил реактивность с переменной
		// задаю переменной минимальное значение инпута dateFrom (минус 1 год от текущей даты)
		Object.defineProperty(this, 'minDateFrom', {
			value: moment().subtract(1, 'year').format('YYYY-MM-DD'),
			writeable: false
		});
		this.monthData = moment().format('YYYY-MM');
		this.setPage(this.page, this.action, this.limit, this.monthData);
	},
	methods: {
		resetPage() {
			if (this.monthData) {
				this.page = 1;
				this.setPage(this.page, this.action, this.limit, this.monthData);
			}
		},
		/**
		 * Метод який трігириться в компоненті пагінації
		 * на зміну кількості сповіщень в видачі
		 * @param limit
		 */
		onChangeLimit(limit) {
			this.limit = limit;
			set('cabinet_ipp_publications', limit, { expires: EXPIRES });
			this.resetPage();
		},
		/**
		 * Метод який трігириться в компоненті пагінації
		 * перехід по сторінкам
		 * @param page
		 */
		onChangePage(page) {
			if (this.monthData) {
				this.page = page;
				this.setPage(this.page, this.action, this.limit, this.monthData);
			}
		},
		/**
		 * Метод який перераховує кількість сторінок в пагінації
		 * Та перемальовує пангінацію, так як трігерить props - pages
		 */
		getPages() {
			this.pages = Math.ceil(this.count / this.limit);
		},
		setPage(page = 1, action, limit = 10, monthData) {
			let key = [
				'p', page,
				'a', action,
				'l', limit,
				'm', monthData
			].join('');

			if (!this.publications[key]) {
				let data = {
					skip: page * limit - limit,
					limit,
					action,
					monthData
				};

				this.currentPage = [];
				_TARGET_ === 'client' && (this.requestCount < 5) && _laxios.publication_statistic
					.request({
						params: data
					})
					.then(payload => {
						this.publications[key] = payload;
						this.currentPage = payload.data;
						this.count = payload.count.total;
						this.requestCount = 0;
						this.getPages();
					})
					.catch(() => {
						this.requestCount++;
						this.setPage(this.page, this.action, this.limit, this.monthData);
					});
			} else {
				this.count = this.publications[key].count.total;
				this.currentPage = this.publications[key].data;
				this.getPages();
			}
		}
	},
	watch: {
		action() {
			this.resetPage();
		},
		monthData(newValue) {
			this.resetPage();
		},
	},
	computed: {
		...mapGetters({
			publicationStatistic: 'cabinet/publicationsStatistic/publicationsStatistic'
		})
	},
	i18n: {
		messages: {
			uk: {
				'Начисление': 'Нарахування',
				'Расход': 'Витрата',
				'Все типы транзакций': 'Всі типи транзакцій',
				'Отчет об использовании публикаций': 'Звіт про використання публікацій',
				'У Вас нет использованых публикаций за этот период': 'У Вас немає використаних публікацій за цей період'
			},
			ru: {
				'Начисление': 'Начисление',
				'Расход': 'Расход',
				'Все типы транзакций': 'Все типы транзакций',
				'Отчет об использовании публикаций': 'Отчет об использовании публикаций',
				'У Вас нет использованых публикаций за этот период': 'У Вас нет использованых публикаций за этот период'
			}
		}
	}
};
